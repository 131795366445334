const countersSections = document.querySelectorAll('section.counter');
const observer = new IntersectionObserver(handleIntersection);

countersSections.forEach(num => observer.observe(num));

function numCounter(el, maxCount, speed) {
    var initialNumber = 0;
    var timeInterval = 10;
    var totalSteps = speed / timeInterval;
    var incrementStep = (maxCount - initialNumber) / totalSteps;

    function counter() {
        if (initialNumber <= maxCount) {
            if (maxCount.includes('.')) {
                el.innerHTML = initialNumber
                    .toFixed(1)
                    .toLocaleString('pl-PL', { minimumFractionDigits: 1 })
                    .replace('.', ',');
            } else {
                el.innerHTML = Math.floor(initialNumber);
            }

            initialNumber += incrementStep;
        } else {
            if (maxCount.includes('.')) {
                el.innerHTML = parseFloat(maxCount)
                    .toFixed(1)
                    .toLocaleString('pl-PL', { minimumFractionDigits: 1 })
                    .replace('.', ',');
            } else {
                el.innerHTML = maxCount;
            }
            clearInterval(counterDelay);
        }
    }

    var counterDelay = setInterval(counter, timeInterval);
}

function handleIntersection(entries) {
    entries.map(entry => {
        if (entry.isIntersecting) {
            const counters = document.querySelectorAll('.js-counter-item');
            counters.forEach(counter =>
                numCounter(counter, counter.dataset.count, 2000)
            );
            observer.unobserve(entry.target);
        }
    });
}
